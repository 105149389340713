import { BsCheckSquare, BsCurrencyDollar, BsSquare } from 'react-icons/bs';
import PhotoNotAvailable from '../images/PhotoNotAvailable.png';
import Button from './Button';
import { Waypoint } from 'react-waypoint';
import { useContext, useState } from 'react';
import { UsedVehicleContext } from '../context/used-vehicle-context';
import VehicleLimitDialog from './dialogs/VehicleLimitDialog';

type VehicleDetailCardProps = {
    vehiclePicture?: any;
    vehicleYear: any;
    vehicleMake: string;
    vehicleModel: string;
    vehiclePrice: string;
    vehicleMileage: string;
    vehicleLocation: string;
    vehicleVin: string;
    distance: string;
    checkKey: string;
    scrollKey?: string;
    // selectedKeys: Array<string>;
    onEnter?: (e: any) => void;
};

const VehicleDetailCard: React.FC<VehicleDetailCardProps> = (
    props: VehicleDetailCardProps
) => {
    const { usedVehicleState, updateUsedVehicleState } =
        useContext(UsedVehicleContext);
    const { selectedKeys, selectedInventory } = usedVehicleState;
    const [ vehicleLimitMsg, setVehicleLimitMsg ] = useState<boolean>(false);

    // const imageUrl = `https://img.leaddelivery.net/images/${ props.vehicleVin }/Main/${ props.vehiclePicture }.jpg`;
    // This will be the new image url schema
    const imageUrl = `https://imagehive.leaddelivery.net/inventory/image/${ props.vehicleVin }/main/${ props.vehiclePicture }.jpg`;
    const vehicleVin = props.checkKey;

    const selectHandler = (e: any) => {
        e.preventDefault();
        const selectedKeysCopy = [ ...selectedKeys ];
        const selectedInventoryCopy = [ ...selectedInventory ];
        const lengthCheck =
            selectedKeysCopy.length < 5 && selectedInventoryCopy.length < 5;
        if (selectedKeysCopy.includes(vehicleVin)) {
            const index = selectedKeysCopy.indexOf(vehicleVin);
            if (index > -1) {
                selectedKeysCopy.splice(index, 1);
                selectedInventoryCopy.splice(index, 1);
            }
        } else {
            if (lengthCheck) {
                selectedKeysCopy.push(vehicleVin);
                selectedInventoryCopy.push(props);
            } else {
                setVehicleLimitMsg(true);
            }
        }
        updateUsedVehicleState({
            ...usedVehicleState,
            selectedKeys: selectedKeysCopy,
            selectedInventory: selectedInventoryCopy,
        });
    };

    const vehicleLimitMsgHandler = () => {
        setVehicleLimitMsg(!vehicleLimitMsg);
    };

    return (
        <div className="flex max-h-[548px] w-full max-w-[25rem] flex-col overflow-clip rounded bg-white shadow-card md:w-60 md:flex-grow">
            <div>
                <img
                    className="aspect-[16/9] w-full rounded-t"
                    src={ props.vehiclePicture ? imageUrl : PhotoNotAvailable }
                />
            </div>
            <div className="flex h-[4.5rem] w-full items-center justify-start border-b-[1px] border-stone-300 p-3 text-base font-bold">
                { props.vehicleYear }&nbsp;
                { props.vehicleMake }&nbsp;
                { props.vehicleModel }
            </div>
            <div className="flex h-12 w-full items-center border-b-[1px] border-stone-300">
                <div className="relative w-1/2 border-r-[1px] border-stone-300 p-3 text-base">
                    <BsCurrencyDollar className="absolute left-2 top-3 text-sm" />
                    <p className="ml-2">
                        { Math.round(
                            Number(props.vehiclePrice)
                        ).toLocaleString() }
                    </p>
                </div>
                <div className="w-1/2 p-3">
                    <p>
                        { Number(props.vehicleMileage).toLocaleString() }&nbsp;mi
                    </p>
                </div>
            </div>
            <div className="h-16 border-b-[1px] border-stone-300 p-3">
                <p className="overflow-hidden text-ellipsis whitespace-nowrap text-base font-normal">
                    { props.vehicleLocation }
                </p>
                <p className="text-xs">{ props.distance } mi away</p>
            </div>
            <div className="p-3">
                <label
                    onClick={ (e) => selectHandler(e) }
                    className={
                        'box-border flex h-[40px] cursor-pointer items-center justify-center rounded border-[1px] border-blue-500 bg-blue-500 py-[11px] text-sm font-bold' +
                        (selectedKeys.includes(vehicleVin)
                            ? ' bg-opacity-0 text-blue-500'
                            : ' bg-opacity-100 text-white shadow-card')
                    }
                    htmlFor={ vehicleVin }
                >
                    <input
                        id={ vehicleVin }
                        type="checkbox"
                        className="checked:focus:ring-none form-checkbox pointer-events-none mr-2 h-[1.125rem] w-[1.125rem] rounded border-2 border-white bg-transparent checked:border-blue-500 checked:bg-blue-500 checked:focus:bg-blue-500 checked:focus-visible:bg-blue-500"
                        checked={ selectedKeys.includes(vehicleVin) }
                        readOnly
                    />
                    { selectedKeys.includes(vehicleVin)
                        ? 'VEHICLE SELECTED'
                        : 'SELECT VEHICLE' }
                </label>
            </div>
            { props.scrollKey !== undefined && (
                <Waypoint onEnter={ props.onEnter } />
            ) }
            <VehicleLimitDialog
                isOpen={ vehicleLimitMsg }
                closeDialog={ () => vehicleLimitMsgHandler() }
            />
        </div>
    );
};
export default VehicleDetailCard;
